<template>
  <layout :title="$route.name" :options="options" :rotas="rotas">
    <div
      style="min-height: 93vh; max-height: 93vh; overflow: auto; align-items: flex-start !important; justify-content: flex-start !important;"
      class="expande-horizontal windows-style-content wrap"
    >
      <div class="expande-horizontal wrap">
        <v-flex
          style="min-height: 100%; max-height: 100%; overflow: auto; align-items: flex-start !important; justify-content: flex-start !important;"
          xs12
          md3
          class="pl-0 pt-0 pr-3"
        >
          <v-flex xs12>
            <div
              style="min-height: 82vh; max-height: 100%; align-items: flex-start;"
              class="expande-horizontal wrap windows-style-content"
            >
              <v-flex class="pa-3 pb-0" xs12>
                <div class="expande-horizontal px-0 wrap">
                  <v-chip
                    :color="
                      period.description === 'today'
                        ? $theme.primary
                        : 'grey lighten-2'
                    "
                    @click="getToday({ description: 'today' })"
                    class="fonte mt-1"
                    small
                  >
                    Hoje
                  </v-chip>

                  <v-chip
                    :key="index"
                    v-for="(filter, index) in filtersBox"
                    @click="getFilterPersonalized(filter)"
                    class="fonte ml-1 mt-1"
                    :class="{
                      'pr-0': period.description !== filter.description,
                      'pr-3': period.description === filter.description
                    }"
                    small
                    :color="
                      period.description === filter.description
                        ? $theme.primary
                        : 'grey lighten-2'
                    "
                  >
                    {{ filter.description }}
                    <v-icon
                      v-if="period.description !== filter.description"
                      @click="removeFilter(index)"
                      color="grey lighten-1"
                      class="ml-1"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-chip>
                  <v-btn @click="openModalCreateFilter" icon>
                    <v-icon>mdi-magnify-plus-outline</v-icon>
                  </v-btn>
                </div>
              </v-flex>
              <div
                class="expande-horizontal"
                style="justify-content: flex-start; align-items: flex-start; min-height: 100%; max-height: 100%; overflow: auto;"
              >
                <v-flex class="px-2" xs12>
                  <v-list
                    class="fonte"
                    dense
                    style="min-height: 78vh; max-height: 78vh; min-width: 100%;"
                    color="transparent"
                  >
                    <template v-for="(box, i) in get_caixas.boxs">
                      <v-list-item
                        :class="{
                          'white--text box-selected':
                            currentBox?._id === box._id
                        }"
                        @click="selectBox(box)"
                        :key="box._id"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ box.funcionario.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            :class="{
                              'white--text box-selected':
                                currentBox._id === box._id
                            }"
                          >
                            <v-icon size="8" :color="getBoxColorStatus(box)"
                              >mdi-circle
                            </v-icon>
                            {{
                              $moment(box.created_at).format(
                                "DD/MM/YY [às] HH:mm"
                              )
                            }}
                            <!-- <span v-if="box.data_fechamento">
                              até
                              {{
                                $moment(box.data_fechamento).format(
                                  "DD/MM/YY [às] HH:mm"
                                )
                              }}
                            </span>
                            <span v-else>
                              (ainda aberto)
                            </span> -->
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if="currentBox._id === box._id">
                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
              </div>
            </div>
          </v-flex>
        </v-flex>
        <v-flex xs12 md9>
          <div
            style="min-height: 81vh; max-height: 100%;"
            class="expande-horizontal wrap"
          >
            <v-flex class="pt-0" v-if="$vuetify.breakpoint.lgAndUp" xs12>
              <div
                leave-active-class="animate__animated animate__fadeIn"
                class="expande-horizontal wrap animate__animated animate__fadeIn"
              >
                <div class="expande-horizontal column windows-style">
                  <v-list two-line color="transparent" class="pa-0 ma-0 pt-1 pb-2">
                    <v-list-item class="fonte">
                      <v-avatar color="#333" dark class="mr-2">
                        <v-icon :color="$theme.primary">mdi-target</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="">
                          {{
                            currentBox.funcionario?.nome || "Selecione um caixa"
                          }}
                        </v-list-item-title>
                        <span class="fonte fonteMini grey--text">
                          <v-icon
                            size="8"
                            :color="getBoxColorStatus(currentBox)"
                            >mdi-circle
                          </v-icon>
                          Aberto
                          {{
                            $moment(currentBox.created_at).format(
                              "DD/MM/YY [às] HH:mm"
                            )
                          }}
                          <span v-if="currentBox.data_fechamento">
                            até
                            {{
                              $moment(currentBox.data_fechamento).format(
                                "DD/MM/YY [às] HH:mm"
                              )
                            }}
                          </span>
                          <span class="green--text" v-else>
                            (ainda aberto)
                          </span>
                        </span>
                        <span v-if="currentBox._id" class="fonte fonteMini">
                          Há {{ currentBox.transactions.length }} registros para aferir neste caixa
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div style="max-height: 80vh; overflow: auto; overflow-x: hidden;" class="windows-style-content expande-horizontal wrap">
                  <!-- Dados da caixa form -->
                  <v-flex v-if="currentBox._id" xs12>
                    <div class="expande-horizontal centraliza wrap pa-3">
                      <v-flex v-if="false" xs12 md4>
                        <div class="expande-horizontal">
                          <Chart
                            type="polarArea"
                            description="Entradas"
                            ref="chartEntries"
                            :labels="['Entrada Dinheiro', 'Entrada Pix', 'Entrada Débito', 'Entrada Crédito']"
                            :dataChart="[
                              reportValues.total_entrada_dinheiro,
                              reportValues.total_entrada_pix,
                              reportValues.total_entrada_debito,
                              reportValues.total_entrada_credito
                            ]"
                            id="chart-in"
                            :height="50"
                            :width="100"
                          />
                        </div>
                      </v-flex>
                      <v-flex v-if="false" xs12 md4>
                        <div class="expande-horizontal">
                          <Chart
                            type="polarArea"
                            description="Saídas"
                            ref="chartOut"
                            :labels="['Saída Dinheiro', 'Saída Pix', 'Saída Débito', 'Saída Crédito']"
                            :dataChart="[
                              reportValues.total_saida_dinheiro,
                              reportValues.total_saida_pix,
                              reportValues.total_saida_debito,
                              reportValues.total_saida_credito
                            ]"
                            id="chart-out"
                            :height="50"
                            :width="100"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="fonte expande-horizontal px-3 column">
                          <h1 class="font-weight-light" :class="balance > 0 ? 'green--text' : 'red--text'">
                            {{ $helper.formataSaldo(balance) }}
                            <v-icon size="31" color="green" v-if="balance > 0">mdi-trending-up</v-icon>
                            <v-icon size="31" color="red" v-if="balance < 0">mdi-trending-down</v-icon>
                            <v-icon size="26" color="orange" v-if="balance == 0">mdi-database-sync-outline</v-icon>
                          </h1>
                          <span class="grey--text fonteMini" style="margin-top: -10px;">balanço do caixa</span>
                        </div>
                      </v-flex>
                      <v-flex class="mt-3" xs12>
                        <div class="expande-horizontal centraliza">
                          <v-flex xs12 md3>
                            <v-list nav dense color="transparent" class="fonte">
                              <v-list-item :style="`border-radius: 6px; border: 1px solid #e5e5e5; background: #e5e5e5;`">
                                <v-list-item-content>
                                  <v-list-item-title class="font-weight-bold">
                                    <div class="expande-horizontal">
                                      DINHEIRO
                                      <v-spacer></v-spacer>
                                      <v-icon
                                        size="15"
                                        class="mr-1"
                                        color="#333"
                                        >mdi-cash</v-icon
                                      >
                                    </div>
                                  </v-list-item-title>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Caixa Cego
                                      </span>
                                      <span class="grey--text">
                                        {{ $helper.formataSaldo(currentBox.dinheiro_cego || 0) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Calculado
                                      </span>
                                      <span class="grey--text">
                                        {{ $helper.formataSaldo(reportValues.total_entrada_dinheiro - reportValues.total_saida_dinheiro) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Entradas 
                                      </span>
                                      <span class="green--text">
                                        + {{ $helper.formataSaldo(reportValues.total_entrada_dinheiro) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Saídas 
                                      </span>
                                      <span class="red--text">
                                        - {{ $helper.formataSaldo(reportValues.total_saida_dinheiro) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Entradas Canceladas
                                      </span>
                                      <span class="orange--text">
                                        {{ $helper.formataSaldo(reportValues.total_entrada_dinheiro_cancelado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Saídas Canceladas
                                      </span>
                                      <span class="orange--text">
                                        {{ $helper.formataSaldo(reportValues.total_saida_dinheiro_cancelado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle class="py-1">
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span class="green--text font-weight-bold text-uppercase">
                                        APROVADO
                                      </span>
                                      <span class="green--text font-weight-bold text-uppercase">
                                        = {{ $helper.formataSaldo(reportValues.total_entrada_dinheiro_aprovado - reportValues.total_saida_dinheiro_aprovado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-list nav dense color="transparent" class="fonte">
                              <v-list-item :style="`border-radius: 6px; border: 1px solid #e5e5e5; background: #e5e5e5;`">
                                <v-list-item-content>
                                  <v-list-item-title class="font-weight-bold">
                                    <div class="expande-horizontal">
                                      DÉBITO
                                      <v-spacer></v-spacer>
                                      <v-icon
                                        size="15"
                                        class="mr-1"
                                        color="#333"
                                        >mdi-credit-card</v-icon
                                      >
                                    </div>
                                  </v-list-item-title>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Caixa Cego
                                      </span>
                                      <span class="grey--text">
                                        {{ $helper.formataSaldo(currentBox.debito_cego || 0) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Calculado
                                      </span>
                                      <span class="grey--text">
                                        {{ $helper.formataSaldo(reportValues.total_entrada_debito - reportValues.total_saida_debito) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Entradas 
                                      </span>
                                      <span class="green--text">
                                        + {{ $helper.formataSaldo(reportValues.total_entrada_debito) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Saídas 
                                      </span>
                                      <span class="red--text">
                                        - {{ $helper.formataSaldo(reportValues.total_saida_debito) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Entradas Canceladas
                                      </span>
                                      <span class="orange--text">
                                        {{ $helper.formataSaldo(reportValues.total_entrada_debito_cancelado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Saídas Canceladas
                                      </span>
                                      <span class="orange--text">
                                        {{ $helper.formataSaldo(reportValues.total_saida_debito_cancelado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle class="py-1">
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span class="green--text font-weight-bold text-uppercase">
                                        APROVADO
                                      </span>
                                      <span class="green--text font-weight-bold text-uppercase">
                                        = {{ $helper.formataSaldo(reportValues.total_entrada_debito_aprovado - reportValues.total_saida_debito_aprovado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-list nav dense color="transparent" class="fonte">
                              <v-list-item :style="`border-radius: 6px; border: 1px solid #e5e5e5; background: #e5e5e5;`">
                                <v-list-item-content>
                                  <v-list-item-title class="font-weight-bold">
                                    <div class="expande-horizontal">
                                      CRÉDITO
                                      <v-spacer></v-spacer>
                                      <v-icon
                                        size="15"
                                        class="mr-1"
                                        color="#333"
                                        >mdi-credit-card</v-icon
                                      >
                                    </div>
                                  </v-list-item-title>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Caixa Cego
                                      </span>
                                      <span class="grey--text">
                                        {{ $helper.formataSaldo(currentBox.credito_cego || 0) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Calculado
                                      </span>
                                      <span class="grey--text">
                                        {{ $helper.formataSaldo(reportValues.total_entrada_credito - reportValues.total_saida_credito) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Entradas 
                                      </span>
                                      <span class="green--text">
                                        + {{ $helper.formataSaldo(reportValues.total_entrada_credito) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Saídas 
                                      </span>
                                      <span class="red--text">
                                        - {{ $helper.formataSaldo(reportValues.total_saida_credito) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Entradas Canceladas
                                      </span>
                                      <span class="orange--text">
                                        {{ $helper.formataSaldo(reportValues.total_entrada_credito_cancelado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Saídas Canceladas
                                      </span>
                                      <span class="orange--text">
                                        {{ $helper.formataSaldo(reportValues.total_saida_credito_cancelado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle class="py-1">
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span class="green--text font-weight-bold text-uppercase">
                                        APROVADO
                                      </span>
                                      <span class="green--text font-weight-bold text-uppercase">
                                        = {{ $helper.formataSaldo(reportValues.total_entrada_credito_aprovado - reportValues.total_saida_credito_aprovado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-list nav dense color="transparent" class="fonte">
                              <v-list-item :style="`border-radius: 6px; border: 1px solid #e5e5e5; background: #e5e5e5;`">
                                <v-list-item-content>
                                  <v-list-item-title class="font-weight-bold">
                                    <div class="expande-horizontal">
                                      PIX
                                      <v-spacer></v-spacer>
                                      <v-icon
                                        size="15"
                                        class="mr-1"
                                        color="#333"
                                        >mdi-cash</v-icon
                                      >
                                    </div>
                                  </v-list-item-title>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Caixa Cego
                                      </span>
                                      <span class="grey--text">
                                        {{ $helper.formataSaldo(currentBox.pix_cego || 0) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Calculado
                                      </span>
                                      <span class="grey--text">
                                        {{ $helper.formataSaldo(reportValues.total_entrada_pix - reportValues.total_saida_pix) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Entradas 
                                      </span>
                                      <span class="green--text">
                                        + {{ $helper.formataSaldo(reportValues.total_entrada_pix) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Saídas 
                                      </span>
                                      <span class="red--text">
                                        - {{ $helper.formataSaldo(reportValues.total_saida_pix) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Entradas Canceladas
                                      </span>
                                      <span class="orange--text">
                                        {{ $helper.formataSaldo(reportValues.total_entrada_pix_cancelado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle>
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span>
                                        Saídas Canceladas
                                      </span>
                                      <span class="orange--text">
                                        {{ $helper.formataSaldo(reportValues.total_saida_pix_cancelado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                  <v-divider></v-divider>
                                  <v-list-item-subtitle class="py-1">
                                    <div class="expande-horizontal" style="justify-content: space-between;">
                                      <span class="green--text font-weight-bold text-uppercase">
                                        APROVADO
                                      </span>
                                      <span class="green--text font-weight-bold text-uppercase">
                                        = {{ $helper.formataSaldo(reportValues.total_entrada_pix_aprovado - reportValues.total_saida_pix_aprovado) }}
                                      </span>
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                        </div>
                      </v-flex>
                    </div>
                  </v-flex>
                  <v-flex v-if="currentBox._id" class="pa-3" xs12>
                    <v-flex
                      xs12
                    >
                      <v-timeline style="min-height: 79vh;" align-top dense>
                        <v-timeline-item
                          v-for="(item, i) in currentBox.transactions"
                          :key="item._id"
                          :color="filterStatus(item.current_status).color"
                          icon-color="#333"
                          icon="mdi-fingerprint"
                          class="fonte"
                        >
                          <v-flex xs12 class="pt-1">
                            <div
                              v-if="item.origin === 'sale'"
                              class="expande-horizontal column wrap"
                            >
                              <span
                                style="cursor: pointer;"
                                @click="
                                  abre_modal_view_gestordepedido(item.sale)
                                "
                              >
                                <v-icon color="#333">mdi-eye</v-icon>

                                Entrada de valor para a venda
                                <v-chip outlined small class="font-weight-bold"
                                  >#{{ item.sale.id_transacao }}</v-chip
                                >
                                do cliente
                                {{ item.sale.compra?.cliente?.nome }}
                              </span>
                            </div>
                            <div
                              v-if="item.origin === 'invoice'"
                              class="expande-horizontal column wrap"
                            >
                              <span
                                style="cursor: pointer;"
                                @click="
                                  abre_modal_view_gestordepedido(item.sale)
                                "
                              >
                                <!-- <v-icon color="#333">mdi-eye</v-icon> -->

                                Parcela da conta {{ item.invoice.description }} {{
                                  item.charge.invoice_type === "to_pay"
                                    ? "paga"
                                    : "recebida"
                                }}
                              </span>
                            </div>
                            <div
                              v-if="item.origin === 'stock'"
                              class="expande-horizontal column wrap"
                            >
                              <span
                                style="cursor: pointer;"
                                @click="
                                  abre_modal_view_gestordepedido(item.sale)
                                "
                              >
                                <!-- <v-icon color="#333">mdi-eye</v-icon> -->

                                Entrada de estoque de
                                {{ item.stock.produto.nome }}
                                <v-chip outlined small class="font-weight-bold"
                                  >#{{ item.stock.id_estoque }}</v-chip
                                >
                              </span>
                            </div>
                            <div
                              v-if="item.origin === 'worth'"
                              class="expande-horizontal column wrap"
                            >
                              <span
                                style="cursor: pointer;"
                                @click="
                                  abre_modal_view_gestordepedido(item.sale)
                                "
                              >
                                <!-- <v-icon color="#333">mdi-eye</v-icon> -->

                                Vale para {{ item.charge.client.nome }}
                              </span>
                            </div>
                            <v-list color="transparent" class="pa-0 ma-0" three-line dense>
                              <v-list-item
                                class="fonte"
                                style="max-height: 40px; margin-bottom:3px; background: #e0e0e0; border-radius: 6px;"
                              >
                                <!-- <v-avatar size="21" class="mr-2">
                                  
                                </v-avatar> -->
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{
                                      $helper.formataSaldo(
                                        item.total_without_taxs
                                      )
                                    }}
                                  <span class="grey--text text-lowercase fonteMini">
                                    {{
                                      $moment(item.created_at).format(
                                        "DD/MM/YY [às] HH:mm"
                                      )
                                    }}
                                  </span>
                                  </v-list-item-title>

                                  <span class="not-selectable fonteMini signed-gradient text-lowercase"> <v-icon size="15" class="signed-gradient">mdi-fingerprint</v-icon> {{item._id}}{{ item.path ? `-${item.path}` : '' }}</span>
                                  <span class="mt-1 fonteMini">
                                    <v-icon
                                    size="15"
                                    class="mr-1"
                                    :color="
                                      filterStatus(item.current_status).color
                                    "
                                    >{{
                                      filterStatus(item.current_status).icon
                                    }}</v-icon
                                  >Situação: {{ filterStatus(item.current_status).text }} <v-chip v-if="item.cancel_reason" x-small color="red lighten-1" dark class="pl-1"> <v-icon color="white" class="mr-1" size="12">mdi-alert-outline</v-icon> {{ item.cancel_reason }}</v-chip> <v-chip v-if="item.current_status === 'approved'" dark x-small color="green"> {{ item.user_who_approved.nome }}</v-chip> <v-chip v-if="item.current_status === 'approved'" dark x-small color="green"> {{ item.payment_form }}</v-chip>
                                  </span>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    x-small
                                    tile
                                    depressed
                                    @click="openViewSignature(item)"
                                    color="#f2f2f2"
                                  >
                                    <span v-if="item.current_status !== 'approved'">aferir</span>
                                    <span v-else>{{ $moment(item.approve_date).format('DD/MM/YY [às] HH:mm') }}</span>
                                    <v-icon class="signed-gradient ml-1" size="15">mdi-fingerprint</v-icon>
                                  </v-btn>
                                  <!-- <v-btn
                                    x-small
                                    class="mt-2 elevation-0"
                                    tile
                                    color="#f2f2f2"
                                  >
                                    Aprovar
                                    <v-icon class="signed-gradient" size="15">mdi-check-all</v-icon>
                                  </v-btn> -->
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                        </v-timeline-item>
                        <v-timeline-item
                          color="red"
                          v-if="
                            !currentBox._id &&
                              currentBox.transactions.length === 0
                          "
                        >
                          <v-flex xs12>
                            <div
                              style="min-height: 57vh;"
                              class="expande-horizontal fonte centraliza column"
                            >
                              <v-icon>mdi-target</v-icon>
                              <v-progress-linear indeterminate v-if="loading">
                              </v-progress-linear>
                              <h3>Nenhuma transação encontrada</h3>
                              <span
                                >Nenhuma transação foi encontrada para o caixa
                                selecionado</span
                              >
                            </div>
                          </v-flex>
                        </v-timeline-item>
                      </v-timeline>
                    </v-flex>
                  </v-flex>
                  <v-flex v-else xs12>
                    <div
                      style="min-height: 84vh;"
                      class="centraliza fonte expande-horizontal"
                    >
                      <div class="expande-horizontal centraliza column">
                        <v-icon>mdi-target</v-icon>
                        <h3>Conferência de caixa</h3>
                        <span
                          >Escolhe um caixa para realizar a aferência de
                          valores</span
                        >
                      </div>
                    </div>
                  </v-flex>
                </div>
              </div>
            </v-flex>
          </div>
        </v-flex>
        <v-flex v-if="false" xs12 md3 class="pa-3 pt-0 pr-0">
          <div
            style="min-height: 85vh; max-height: 85vh; overflow: auto;"
            class="expande-horizontal windows-style-content wrap"
          >
            <v-flex v-if="currentBox._id" xs12>
              <div class="expande-horizontal">
                <div class="expande-horizontal">
                  <Chart
                    type="doughnut"
                    description="Valor"
                    ref="chartEntries"
                    :labels="['Entrada Dinheiro', 'Entrada Pix', 'Entrada Débito', 'Entrada Crédito']"
                    :dataChart="[
                      reportValues.total_entrada_dinheiro,
                      reportValues.total_entrada_pix,
                      reportValues.total_entrada_debito,
                      reportValues.total_entrada_credito
                    ]"
                    id="chart-in"
                    :height="200"
                    :width="200"
                  />
                </div>
                <div class="expande-horizontal">
                  <Chart
                    type="doughnut"
                    description="Valor"
                    ref="chartOut"
                    :labels="['Saída Dinheiro', 'Saída Pix', 'Saída Débito', 'Saída Crédito']"
                    :dataChart="[
                      reportValues.total_saida_dinheiro,
                      reportValues.total_saida_pix,
                      reportValues.total_saida_debito,
                      reportValues.total_saida_credito
                    ]"
                    id="chart-out"
                    :height="200"
                    :width="200"
                  />
                </div>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </div>
    </div>
    <ViewSale :receipt="receipt" :payment_sys_config="payment_sys_config" />
    <SignedTransaction ref="viewSignature" :updateSignInList="updateSignInList" />
    <v-dialog width="300" v-model="newFilterDialog">
      <v-card class="pa-3 pt-0" width="300">
        <div class="expande-horizontal centraliza">
          <v-list dense>
            <v-list-item class="pa-0 ma-0 fonte">
              <v-avatar :color="$theme.primary" class="mr-2">
                <v-icon color="white">mdi-filter</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  Filtro Personalizado
                </v-list-item-title>
                <v-list-item-subtitle>
                  Novo filtro personalizado
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="closeFilterDialog" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <v-flex class="fonte" xs12>
          <v-form ref="formNewFilter">
            <v-flex xs12>
              <v-text-field
                v-model="newFilterForm.description"
                label="Nome do filtro *"
                dense
                autofocus
                placeholder="ex: Janeiro de 2023"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-autocomplete
                v-model="newFilterForm.seller"
                label="Vendedor"
                placeholder="ex: Sara"
                item-text="nome"
                return-object
                :items="get_funcionarios.docs"
                dense
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="newFilterForm.startDate"
                label="Data de início *"
                v-mask="['##/##/####']"
                :rules="[
                  v => !!v || 'Campo obrigatório',
                  v => v.length === 10 || 'Data inválida'
                ]"
                placeholder="ex: 01/01/2023"
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="newFilterForm.endDate"
                label="Data Final"
                v-mask="['##/##/####']"
                placeholder="ex: 01/01/2023"
                dense
              ></v-text-field>
            </v-flex>
          </v-form>
          <v-flex xs12>
            <v-btn dark :color="$theme.secondary" @click="createFilter" block>
              Salvar
            </v-btn>
          </v-flex>
        </v-flex>
      </v-card>
    </v-dialog>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="faturamento"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-quality="2"
      pdf-content-width="100"
      pdf-orientation="portrait"
      @progress="() => {}"
      @hasStartedGeneration="() => {}"
      @hasDownloaded="() => {}"
      ref="faturamentoPdf"
    >
      <div slot="pdf-content">
        <section class="pdf-item fonte">
          <div
            id="print-html"
            style=" width: 100%; padding: 6px; display: flex; flex-direction: column; flex-wrap: nowrap;"
          >
            <div
              style="width: 100%; margin-bottom: 30px; padding-bottom: 30px; padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
            >
              <div
                style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
              >
                <div
                  style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
                >
                  <img style="height: 50px;" :src="tenant.profileImage" alt="" />
                </div>
                <div
                  style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
                >
                  <strong style="text-align: center; font-size: 9pt;">
                    {{ tenant.nome }}
                  </strong>
                  <span
                    style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
                  >
                    CNPJ: {{ tenant.cnpj }} IE: {{ tenant.ie }}
                  </span>
                  <span
                    v-if="tenant.address"
                    style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
                  >
                    {{ tenant.address.formatted_address }}
                  </span>
                  <span
                    style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                  >
                    FONE: {{ tenant.telefone }}
                  </span>
                </div>
                <div
                  style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
                ></div>
              </div>
              <div
                class="mt-3 fonte centraliza"
                style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
              >
                <h3 class="font-weight-light">Relatório de Caixa #{{ currentBox.id_caixa }} </h3>
                <span>{{ currentBox.funcionario?.nome }}</span>
              </div>
              <div
                style="margin-top: 50px; margin-bottom: 100px;"
                class="expande-horizontal centraliza pa-6"
              >
                <h3>Total de Entradas</h3>
                <table>
                  <thead>
                    <tr>
                      <th
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        ENTRADA DINHEIRO
                      </th>
                      <th
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        ENTRADA DÉBITO
                      </th>
                      <th
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        ENTRADA CRÉDITO
                      </th>
                      <th
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        ENTRADA PIX
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      :key="`report-entries`"
                    >
                      <td
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        {{ reportValues.total_entrada_dinheiro }}
                      </td>
                      <td
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        {{ reportValues.total_entrada_debito }}
                      </td>
                      <td
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        {{ reportValues.total_entrada_credito }}
                      </td>
                      <td
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        {{ reportValues.total_entrada_pix }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style="margin-top: 50px; margin-bottom: 100px;"
                class="expande-horizontal centraliza pa-6"
              >
                <h3>Total de Saídas</h3>
                <table>
                  <thead>
                    <tr>
                      <th
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        SAÍDA DINHEIRO
                      </th>
                      <th
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        SAÍDA DÉBITO
                      </th>
                      <th
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        SAÍDA CRÉDITO
                      </th>
                      <th
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        SAÍDA PIX
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      :key="`report-entries`"
                    >
                      <td
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        {{ reportValues.total_saida_dinheiro }}
                      </td>
                      <td
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        {{ reportValues.total_saida_debito }}
                      </td>
                      <td
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        {{ reportValues.total_saida_credito }}
                      </td>
                      <td
                        style="text-align: left; font-size: 12pt; padding: 6px;"
                      >
                        {{ reportValues.total_saida_pix }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="expande-horizontal centraliza">
                <v-flex
                  style="border-radius: 4px; min-height: 100px; margin-top: 12px"
                  xs12
                >
                  <div
                    style="margin-top: 50px;"
                    class="expande-horizontal centraliza"
                  >
                    __________________________________________________
                  </div>
                  <div class="expande-horizontal mb-6 centraliza">
                    Assinatura
                  </div>
                </v-flex>
              </div>
            </div>
          </div>
        </section>
      </div>
    </vue-html2pdf>
  </layout>
</template>

<script>
import ViewSale from "@/apps/GestorDePedido/components/ViewSale";
import SignedTransaction from "@/apps/SignedTransaction/views/SignedTransaction";
import Chart from "../components/Chart";

import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      period: "day",
      options: [],
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Caixas",
          path: "/caixas"
        }
      ],
      currentBox: {
        _id: false,
        new: false,
        transaction: []
      },
      filter: {
        search: ""
      },
      newFilterDialog: false,
      newFilterForm: {},
      filtersBox: [],
      payment_sys_config: {},
      receipt: {
        show_my_address: { active: true },
        show_my_logo: { active: true },
        show_my_qrcode: { active: true },
        show_client_details: { active: true },
        show_header_text: { active: true, text: "" },
        show_footer_text: { active: true, text: "" }
      },
      report: []
    };
  },
  components: {
    ViewSale,
    SignedTransaction,
    Chart
  },
  computed: {
    ...mapGetters([
      "getLoggedUser",
      "get_caixa",
      "get_caixas",
      "get_caixas_filtros",
      "get_funcionarios",
      "getPersonalizedLoading"
    ]),
    tenant() {
      return this.getLoggedUser.tenant[0] || {};
    },
    balance() {
      return (
        this.reportValues.total_entrada_dinheiro_aprovado +
        this.reportValues.total_entrada_pix_aprovado +
        this.reportValues.total_entrada_debito_aprovado +
        this.reportValues.total_entrada_credito_aprovado -
        this.reportValues.total_saida_dinheiro_aprovado -
        this.reportValues.total_saida_pix_aprovado -
        this.reportValues.total_saida_debito_aprovado -
        this.reportValues.total_saida_credito_aprovado
      )
    },
    reportValues() {
      let total_entrada_dinheiro = 0;
      let total_entrada_pix = 0;
      let total_entrada_debito = 0;
      let total_entrada_credito = 0;

      let total_saida_dinheiro = 0;
      let total_saida_pix = 0;
      let total_saida_debito = 0;
      let total_saida_credito = 0;

      let total_saida_dinheiro_aprovado = 0;
      let total_saida_pix_aprovado = 0;
      let total_saida_debito_aprovado = 0;
      let total_saida_credito_aprovado = 0;

      let total_saida_dinheiro_cancelado = 0;
      let total_saida_pix_cancelado = 0;
      let total_saida_debito_cancelado = 0;
      let total_saida_credito_cancelado = 0;
      
      let total_entrada_dinheiro_aprovado = 0;
      let total_entrada_pix_aprovado = 0;
      let total_entrada_debito_aprovado = 0;
      let total_entrada_credito_aprovado = 0;

      let total_entrada_dinheiro_cancelado = 0;
      let total_entrada_pix_cancelado = 0;
      let total_entrada_debito_cancelado = 0;
      let total_entrada_credito_cancelado = 0;

      const processPayment = payment => {
        switch (payment.payment_form) {
          case "pix":
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive') {
              total_entrada_pix += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'approved') {
              total_entrada_pix_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'canceled') {
              total_entrada_pix_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay') {
              total_saida_pix += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'approved') {
              total_saida_pix_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'canceled') {
              total_saida_pix_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'sale') {
              total_entrada_pix += payment.total_without_taxs;
            }
            if (payment.origin === 'sale' && payment.current_status === 'approved') {
              total_entrada_pix_aprovado += payment.total_without_taxs;
            }            
            if (payment.origin === 'sale' && payment.current_status === 'canceled') {
              total_entrada_pix_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock') {
              total_saida_pix += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'approved') {
              total_saida_pix_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'canceled') {
              total_saida_pix_cancelado += payment.total_without_taxs;
            }
            break;
          case "dinheiro":
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive') {
              total_entrada_dinheiro += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'approved') {
              total_entrada_dinheiro_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'canceled') {
              total_entrada_dinheiro_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay') {
              total_saida_dinheiro += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'approved') {
              total_saida_dinheiro_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'canceled') {
              total_saida_dinheiro_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'sale') {
              total_entrada_dinheiro += payment.total_without_taxs;
            }
            if (payment.origin === 'sale' && payment.current_status === 'approved') {
              total_entrada_dinheiro_aprovado += payment.total_without_taxs;
            }            
            if (payment.origin === 'sale' && payment.current_status === 'canceled') {
              total_entrada_dinheiro_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock') {
              total_saida_dinheiro += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'approved') {
              total_saida_dinheiro_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'canceled') {
              total_saida_dinheiro_cancelado += payment.total_without_taxs;
            }
            break;
          case "debito":
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive') {
              total_entrada_debito += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'approved') {
              total_entrada_debito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'canceled') {
              total_entrada_debito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay') {
              total_saida_debito += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'approved') {
              total_saida_debito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'canceled') {
              total_saida_debito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'sale') {
              total_entrada_debito += payment.total_without_taxs;
            }
            if (payment.origin === 'sale' && payment.current_status === 'approved') {
              total_entrada_debito_aprovado += payment.total_without_taxs;
            }            
            if (payment.origin === 'sale' && payment.current_status === 'canceled') {
              total_entrada_debito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock') {
              total_saida_debito += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'approved') {
              total_saida_debito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'canceled') {
              total_saida_debito_cancelado += payment.total_without_taxs;
            }
            break;
          case "credito":
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive') {
              total_entrada_credito += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'approved') {
              total_entrada_credito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_receive' && payment.current_status === 'canceled') {
              total_entrada_credito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay') {
              total_saida_credito += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'approved') {
              total_saida_credito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'invoice' && payment.charge.invoice_type === 'to_pay' && payment.current_status === 'canceled') {
              total_saida_credito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'sale') {
              total_entrada_credito += payment.total_without_taxs;
            }
            if (payment.origin === 'sale' && payment.current_status === 'approved') {
              total_entrada_credito_aprovado += payment.total_without_taxs;
            }            
            if (payment.origin === 'sale' && payment.current_status === 'canceled') {
              total_entrada_credito_cancelado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock') {
              total_saida_credito += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'approved') {
              total_saida_credito_aprovado += payment.total_without_taxs;
            }
            if (payment.origin === 'stock' && payment.current_status === 'canceled') {
              total_saida_credito_cancelado += payment.total_without_taxs;
            }
            break;
        }
      };
      if (this.currentBox.transactions) {
        this.currentBox.transactions.map(transaction =>
          processPayment(transaction)
        );
      }
      return {
        total_entrada_dinheiro,
        total_entrada_pix,
        total_entrada_debito,
        total_entrada_credito,
        total_saida_dinheiro,
        total_saida_pix,
        total_saida_debito,
        total_saida_credito,
        total_saida_dinheiro_aprovado,
        total_saida_pix_aprovado,
        total_saida_debito_aprovado,
        total_saida_credito_aprovado,
        total_saida_dinheiro_cancelado,
        total_saida_pix_cancelado,
        total_saida_debito_cancelado,
        total_saida_credito_cancelado,
        total_entrada_dinheiro_aprovado,
        total_entrada_pix_aprovado,
        total_entrada_debito_aprovado,
        total_entrada_credito_aprovado,
        total_entrada_dinheiro_cancelado,
        total_entrada_pix_cancelado,
        total_entrada_debito_cancelado,
        total_entrada_credito_cancelado
      };
    }
  },
  methods: {
    ...mapActions([
      "listar_caixas_admin",
      "criar_caixa",
      "atualizar_caixa",
      "createGlobalMessage",
      "createConfirmAction",
      "abre_modal_view_gestordepedido",
      "listar_funcionarios"
    ]),
    updateSignInList(val) {
      this.currentBox.transactions = this.currentBox.transactions.map(
        transaction => {
          if (transaction._id === val._id) {
            return val;
          }
          return transaction;
        }
      );
    },
    openViewSignature(val) {
      this.$refs.viewSignature.open(val);
    },
    filterStatus(val) {
      if (!val) return { text: "Aguardando", color: "orange" };
      const status = {
        pending: {
          icon: "mdi-clock-outline",
          text: "Aguardando aferição",
          color: "orange lighten-2"
        },
        fine_mesh: {
          icon: "mdi-magnify-scan",
          text: "Malha fina",
          color: "orange"
        },
        fine_mesh: {
          icon: "mdi-magnify-scan",
          text: "Malha fina Checada",
          color: "orange"
        },
        canceled: {
          icon: "mdi-close-circle-outline",
          text: "Cancelado",
          color: "red"
        },
        approved: {
          icon: "mdi-check-circle-outline",
          text: "Aprovado",
          color: "green"
        }
      };
      return status[val];
    },
    getBoxColorStatus(box) {
      const dueBox = this.$moment()
        .startOf("day")
        .isAfter(this.$moment(box.data_abertura).startOf("day"));

      if (box.data_abertura && !box.data_fechamento && !dueBox) {
        return "orange";
      }
      if (box.data_abertura && box.data_fechamento && !dueBox) {
        return "green";
      }
      if (dueBox) {
        return "red";
      }
    },
    selectBox(box) {
      this.loading = true;
      this.$run("box/view", { id: box._id }).then(res => {
        this.loading = false;
        this.currentBox = res.data;
      });
      this.$refs.chartOut.unmountChart();
      this.$refs.chartEntries.unmountChart();
      setTimeout(() => {
        this.$refs.chartOut.montaChart();
        this.$refs.chartEntries.montaChart();
      }, 300);
    },
    generatePdf() {
      this.$refs.faturamentoPdf.generatePdf();
    },
    openModalCreateFilter() {
      this.newFilterDialog = true;
      this.newFilterForm = {};
    },
    closeFilterDialog() {
      this.newFilterDialog = false;
      this.newFilterForm = {};
    },
    closeBox() {
      this.currentBox = {
        _id: false,
        new: false,
        transaction: []
      };
    },
    deleteBox(item) {
      this.createConfirmAction({
        message: "Deseja realmente excluir esse caixa?",
        icon: "mdi-close-circle",
        action: "excluir_caixa",
        action_value: item
      });
    },
    clearFilters() {
      this.get_caixas_filtros.date = null;
      this.listar_caixas_admin();
    },
    getConfigPaymentSys() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.payment_sys_config = res.data.payment_sys_config;
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    },
    getToday(period) {
      this.period = period;
      const startDate = this.$moment()
        .startOf("day")
        .format("YYYY-MM-DD");
      let periods = [startDate];
      this.get_caixas_filtros.date = periods;
      this.listar_caixas_admin();
    },
    createFilter() {
      if (this.$refs.formNewFilter.validate()) {
        let filters = JSON.parse(localStorage.filtersBox || "[]");
        filters.push(this.newFilterForm);
        localStorage.filtersBox = JSON.stringify(filters);
        this.newFilterDialog = false;
        this.newFilterForm = {};
        this.listFiltersBox();
      }
    },
    listFiltersBox() {
      let filters = JSON.parse(localStorage.filtersBox || "[]");
      this.filtersBox = filters;
    },
    removeFilter(index) {
      let filters = JSON.parse(localStorage.filtersBox || "[]");
      filters.splice(index, 1);
      localStorage.filtersBox = JSON.stringify(filters);
      this.listFiltersBox();
    },
    getFilterPersonalized(period) {
      this.period = period;
      const startDate = this.$moment(period.startDate, "DD/MM/YYYY")
        .startOf("day")
        .utc();
      const endDate = this.$moment(period.endDate, "DD/MM/YYYY").utc();
      let periods = [];
      if (period.endDate) {
        periods = [startDate, endDate];
      } else {
        periods = [startDate];
      }
      this.get_caixas_filtros.date = periods;
      if (period.seller && period.seller._id) {
        this.get_caixas_filtros.seller = { _id: period.seller._id };
      } else {
        this.get_caixas_filtros.seller = false;
      }
      this.listar_caixas_admin();
    },
    openViewSale(sale) {
      if (sale.tipo_de_transacao === "compra") {
        this.abre_modal_view_gestordepedido(sale);
      }
    }
  },
  created() {
    this.listar_funcionarios();
    // this.listar_caixas_admin();
    this.getConfigPaymentSys();
    this.getConfigReceipt();
    this.listFiltersBox();
    this.getToday({ description: "today" });
  }
};
</script>

<style scoped>
.item-selected {
  background: #f2f2f2 !important;
}
.item-new {
  background: #e1673c !important;
  color: #fff !important;
}

.list-categories::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.list-categories::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.list-categories::-webkit-scrollbar-thumb {
  background-color: #e1673c; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.windows-style {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #f2f2f2;
}
.windows-style-content {
  border-bottom: 1px solid #e0e0e0;
  background: #f2f2f2;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.box-selected {
  background: #333 !important;
  border-radius: 6px;
  color: white !important;
}
.signed-gradient {
  /* Primeiro passo: definir um degradê como fundo */
  background-image: linear-gradient(to right, rgb(140, 85, 195), steelblue, rgb(52, 197, 182));
  
  /* Segundo passo: apagar do fundo tudo que não estiver imediatamente atrás de texto */
  background-clip: text;
  -webkit-background-clip: text; /* Alguns navegadores precisam do prefixo */

  /* Terceiro passo: apagar o texto, deixando apenas o fundo atrás dele */
  -webkit-text-fill-color: transparent;

  /* Cor que contraste com o degradê, caso o navegador não suporte `background-clip: text` */
  color: black;
  
}
</style>
