
<template>
  <div
    style="flex-wrap: nowrap;"
    class="windows-style expande-horizontal centraliza fonte"
  >
    <v-list
      color="transparent"
      :dark="$theme.dark"
      class="fonte pa-0 ma-0"
      style="max-width: 94%;"
      dense
      nav
    >
      <v-list-item class="px-3 mx-0">
        <v-avatar class="mr-2" color="#e5e5e5" size="36">
          <v-icon class="signed-gradient">mdi-fingerprint</v-icon>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title class="">
            <span class="fonte">
              {{ signature.origin | filterOrigin }} 
              <span class="grey--text text-lowercase fonteMini">
                {{
                  $moment(signature.created_at).format(
                    "DD/MM/YYYY [às] HH:mm"
                  )
                }}
              </span>
            </span>
          </v-list-item-title>
          <span :style="`color: ${$theme.primary}`">
            <span class="not-selectable fonteMini signed-gradient text-lowercase"> {{signature._id}}{{ signature.path ? `-${signature.path}` : '' }}</span>
          </span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>
    <v-avatar
      @click="close"
      style="cursor: pointer;"
      size="27"
      :color="$theme.primary"
      class="mr-2"
      icon
    >
      <v-icon color="#333">mdi-close</v-icon>
    </v-avatar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: [
    "signature",
    "close",
  ],
  filters: {
    filterOrigin(val) {
      if (!val) return '';
      const origins = {
        worth: 'Pagamento',
        sale: 'Venda',
        stock: 'Estoque',
        invoice: 'Conta'
      }
      return origins[val] || val;
    }
  },
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  methods: {
    ...mapActions(["fecha_modal_view_gestordepedido"])
  }
};
</script>
