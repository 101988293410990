<template>
  <div class="expande-horizontal column px-6 pt-3">
    <div v-if="signature.origin === 'stock'" >
      <!-- <span class="fonteMini">Estoque</span> -->
      <!-- {{ signature.stock }} -->
      <span class="fonte" style="font-size: 18pt;">
        {{ $helper.formataSaldo(signature.total_without_taxs) }}
      </span>
    </div>
    <div v-if="signature.origin === 'invoice'" >
      <!-- <span class="fonteMini">Conta</span> -->
      <!-- {{ signature.invoice }} -->
      <span class="fonte" style="font-size: 18pt;">
        {{ $helper.formataSaldo(signature.total_without_taxs) }}
      </span>
    </div>
    <div v-if="signature.origin === 'sale'" >
      <!-- <span class="fonteMini">Venda</span> -->
      <!-- {{ signature.sale }} -->
      <span class="fonte" style="font-size: 18pt;">
        {{ $helper.formataSaldo(signature.total_without_taxs) }}
      </span>
    </div>
    <div v-if="signature.origin === 'worth'" >
      <!-- <span class="fonteMini">Pagamento</span> -->
      <!-- {{ signature.worth }} -->
      <span class="fonte" style="font-size: 18pt;">
        {{ $helper.formataSaldo(signature.total_without_taxs) }}
      </span>
    </div>
    <span class="fonte fonteMini grey--text">
      Valor sem impostos
    </span>
  </div>
</template>

<script>
export default {
  props: ['signature']
}
</script>