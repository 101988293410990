<template>
  <div class="expande-horizontal fonte column mt-3 px-6 mt-3">
    <div v-if="signature.current_status !== 'approved'" class="expande-horizontal">
      <div class="expande-horizontal wrap">
        <v-chip @click="$emit('input', form_payment.value)" :color="payment_form === form_payment.value ? $theme.third : '#e5e5e5'" small class="ma-1 payment-item" v-for="(form_payment, index) in form_payments" :key="form_payment.name">
          <v-icon v-if="payment_form === form_payment.value" color="white" class="mr-1" size="15">mdi-check</v-icon>
          {{ form_payment.name }}
        </v-chip>
      </div>
    </div>
    <span v-if="!payment_form" class="mt-0 fonteMini grey--text">Forma de Pagamento <span class="red--text">( Informe a forma de pagamento para aprovar )</span> </span>
    <span v-if="signature.current_status === 'approved'" class="mt-0 black--text">
      <v-chip rounded color="green">
        <span class="white--text not-selectable">
          {{ payment_form }}
        </span>
        <v-icon color="white" class="ml-1" size="15">mdi-check-all</v-icon>
      </v-chip>   
    </span>
    <div v-if="signature.current_status !== 'approved' && payment_form" class="expande-horizontal mt-6">
      <v-btn color="#333" class="white--text " depressed @click="approve">
        Aprovar
        <v-progress-circular class="ml-2" size="18" indeterminate v-if="loading"></v-progress-circular>
        <v-icon class="signed-gradient ml-2" v-else>mdi-check</v-icon>
      </v-btn>
    </div>
    <div v-if="signature.current_status === 'approved'" class="expande-horizontal  mt-6">
      <v-chip class="white--text signed-gradient" depressed>
        <span class="signed-gradient not-selectable">
          Aprovada em {{ $moment(signature.approve_date).format('MM/DD/YYYY [às] HH:mm')  }}
        </span>
        <v-icon class="signed-gradient ml-2">mdi-check-all</v-icon>
      </v-chip>
    </div>
    <div v-if="signature.current_status === 'approved'" class="expande-horizontal wrap  mt-6">
      <v-btn v-if="!showCancel" color="red lighten-2" class="white--text " depressed  @click="showCancel = true">
        Cancelar
        <v-progress-circular class="ml-2" size="18" indeterminate v-if="loading"></v-progress-circular>
        <v-icon class="ml-2" color="white" v-else>mdi-close</v-icon>
      </v-btn>
      <v-text-field filled
        append-icon="mdi-close"
        @click:append="showCancel = false"
        v-if="showCancel"
        autofocus
        rounded
        label="Motivo do cancelamento"
        v-model="cancel_reason"
        hide-details
      ></v-text-field>
      <v-flex v-if="showCancel" xs12>
        <v-btn @click="sendCancel" class="mt-2" color="red" text dark small>
          Confirmar Cancelamento
          <v-progress-circular class="ml-2" size="18" indeterminate v-if="loading"></v-progress-circular>
        </v-btn>
      </v-flex>
    </div>
   
  </div>
</template>

<script>
export default {
  props: ['signature', 'close', 'approve', 'loading'],
  computed: {
    payment_form() {
      return this.signature.payment_form;
    }
  },
  data() {
    return {
      showCancel: false,
      cancel_reason: '',
      form_payments: [
        {
          name: 'Dinheiro',
          value: 'dinheiro',
          icon: 'mdi-cash'
        },
        {
          name: 'Cartão de Crédito',
          value: 'credito',
          icon: 'mdi-credit-card-outline'
        },
        {
          name: 'Cartão de Débito',
          value: 'debito',
          icon: 'mdi-credit-card-outline'
        },
        // {
        //   name: 'Cheque',
        //   value: 'cheque',
        //   icon: 'mdi-credit-card-outline'
        // },
        // {
        //   name: 'Boleto',
        //   value: 'boleto',
        //   icon: 'mdi-credit-card-outline'
        // },
        // {
        //   name: 'Transferência',
        //   value: 'transferencia',
        //   icon: 'mdi-credit-card-outline'
        // },
        {
          name: 'Pix',
          value: 'pix',
          icon: 'mdi-credit-card-outline'
        },
        // {
        //   name: 'Outros',
        //   value: 'outros',
        //   icon: 'mdi-credit-card-outline'
        // }
      ],
    }
  },
  methods: {
    sendCancel() {
      this.$emit('cancel', this.cancel_reason);
      setTimeout(() => {
        this.showCancel = false;
        this.cancel_reason = '';
      }, 2000);
    }
  }
}
</script>

<style>
.payment-item {
  border: 1px solid #d0cfcf;display: flex; align-items: center; justify-content: center; height: 30px;
}
</style>